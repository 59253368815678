import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/Dashboard/Dashboard.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/Landing/Heros/HeroForMain.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/ui/Footer/Footer.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/ui/Header/Header.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/ui/Socials/EndCTA/EndCTA.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/ui/TestimonialTooltip/TestimonialTooltip.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
