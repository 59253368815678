"use client";

import Button from "@/components/ui/Button/Button";
import { TestimonialTooltip } from "@/components/ui/TestimonialTooltip/TestimonialTooltip";
import { motion, useInView, useScroll, useTransform } from "framer-motion";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useRef, useState } from "react";

export default function HeroForMain(props) {
    const { scrollY } = useScroll();

    // Déplace l'image vers la gauche en fonction du défilement
    const translateX = useTransform(scrollY, [0, 380], ["0%", "-150%"]);
    const translateX2 = useTransform(scrollY, [0, 380], ["0%", "-100%"]);
    const translateY2 = useTransform(scrollY, [0, 380], ["0%", "150%"]);
    const translateX3 = useTransform(scrollY, [0, 380], ["0%", "100%"]);
    const translateY3 = useTransform(scrollY, [0, 380], ["0%", "150%"]);
    const translateX4 = useTransform(scrollY, [0, 380], ["0%", "100%"]);

    return (
        <div className="relative z-0 md:h-[calc(100vh-114px)]">
            <div className="z-0">
                <motion.div
                    className="absolute w-[240px] aspect-[19/49] md:block hidden -z-10"
                    style={{
                        inset: "0% auto auto 0%",
                        x: translateX,
                    }}
                >
                    <Image
                        src="/images/site/assets/hero-main-top-left.png"
                        alt="Asset"
                        fill
                        unoptimized
                    />
                </motion.div>
                <motion.div
                    className="absolute w-[380px] aspect-[190/141] md:block hidden -z-10"
                    style={{
                        inset: "auto auto 0% 0%",
                        x: translateX2,
                        y: translateY2,
                    }}
                >
                    <Image
                        src="/images/site/assets/hero-main-bottom-left.png"
                        alt="Asset"
                        fill
                        unoptimized
                    />
                </motion.div>
                <motion.div
                    className="absolute w-[391px] aspect-[391/267] md:block hidden -z-10"
                    style={{
                        inset: "auto 0% 0% auto",
                        x: translateX3,
                        y: translateY3,
                    }}
                >
                    <Image
                        src="/images/site/assets/hero-main-bottom-right.png"
                        alt="Asset"
                        fill
                        unoptimized
                    />
                </motion.div>
                <motion.div
                    className="absolute w-[244px] aspect-[488/1133] md:block hidden -z-10"
                    style={{
                        inset: "0% 0% auto auto",
                        x: translateX4,
                    }}
                >
                    <Image
                        src="/images/site/assets/hero-main-top-right.png"
                        alt="Asset"
                        fill
                        unoptimized
                    />
                </motion.div>
            </div>
            <section className="container md:py-40 py-14 z-10">
                <div
                    initial={{ opacity: 0, y: 15 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    <div className="lg:w-[950px] mx-auto xl:background-not-blur md:background-blur-white xl:p-0 md:p-10 p-5 rounded-2xl">
                        <h1
                            className="xl:text-6xl md:text-5xl sm:text-4xl text-3xl font-bold text-center dark:text-white mb-5"
                            style={{ lineHeight: 1.45 }}
                        >
                            Le <span>nouveau</span>{" "}
                            <span className="gradient-text border-2 rounded-xl border-primary px-1.5 py-1 inline-block">
                                Golden Standard
                            </span>{" "}
                            de la formation <span>pour les développeurs</span>
                        </h1>
                        <p
                            className="text-black sm:text-xl text-lg text-center font-inter lg:pr-10"
                            style={{ lineHeight: "170%" }}
                        >
                            Performez sur nos formations enseignées par les
                            meilleurs formateurs au monde. Rejoignez nos
                            accélérateurs ou faites-vous mentorer
                            individuellement pour votre réussite.
                        </p>
                        <div className="mt-5 flex justify-center">
                            <TestimonialTooltip />
                        </div>
                        <div className="mt-10 flex justify-center">
                            <Link href="/signup">
                                <Button type="pop">
                                    Commencer sur Believemy
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
